/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@tailwind utilities;

.formLabel {
  @apply uppercase font-medium text-xs tracking-widest;
}

.sideNavLink {
  @apply cursor-pointer flex w-full py-1 px-2 rounded-full truncate transform duration-200 ease-in;
}

.input {
  @apply flex w-full py-2 px-2 rounded border-2 border-gray focus:ring-green focus:border-green transform duration-200 ease-in;
}

.button {
  @apply rounded-full whitespace-nowrap inline-flex items-center py-2 px-4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green transform duration-300 ease-in-out;
}

.button-large {
  @apply rounded-full whitespace-nowrap inline-flex items-center py-3 px-5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green transform duration-300 ease-in-out;
}

.button-transparent {
  @apply bg-transparent text-black border-2 border-black hover:bg-green hover:text-black;
}

.button-black {
  @apply bg-black text-white border-2 border-black hover:bg-green hover:text-black;
}

.button-green {
  @apply bg-green text-black hover:bg-black hover:text-white;
}

.headline {
  @apply font-display font-normal;
}

.body-large {
  @apply text-lg;
}

.button-arrow {
  @apply stroke-current ml-2;
}

.button-arrow line {
  @apply opacity-0;
}

.button:hover .button-arrow line {
  @apply opacity-100;
  stroke-dasharray: 2 4;
  animation: dash 10s linear infinite;
}

.button-arrow-small {
  @apply stroke-current ml-2;
}

table {
  @apply mb-8 w-full;
}

thead {
  @apply uppercase mb-1.5 px-1.5 text-sm tracking-wide;
}

tr {
  @apply border-b border-gray-200 my-1.5 transform duration-200 ease-in;
}

td {
  @apply py-2.5 px-1.5 text-sm;
}

code {
  font-variant-ligatures: none;
}

@keyframes dash {
  to {
    stroke-dashoffset: 80;
  }
}
