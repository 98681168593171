/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@tailwind utilities;

.formLabel,
thead {
  @apply uppercase font-medium text-xxs tracking-widest;
}

.baseInput {
  @apply block w-full py-2.5 my-1 rounded border text-xs border-ash focus:ring-black focus:border-black hover:border-black transform duration-200 ease-in;
}

.baseInputWithError {
  @apply block w-full py-2.5 my-1 rounded border text-xs border-red focus:ring-red focus:border-red;
}

.inputInFlight {
  @apply cursor-not-allowed opacity-75;
}

.btn {
  @apply w-full flex pl-5 mt-5 py-3 text-sm text-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green transform duration-200 ease-in bg-black hover:bg-green hover:text-black;
}

.btnInFlight {
  @apply cursor-wait animate-pulse;
}

.productHeadline {
  @apply font-display text-2xl pt-3;
}

.btnSecondary {
  @apply items-center text-left px-3 py-2 border border-black text-xs rounded-full bg-white transform duration-200 ease-in;
}

.btnSecondaryActive {
  @apply hover:bg-green hover:border-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green cursor-pointer;
}

.btnTertiary {
  @apply items-center text-left px-4 py-2 border border-ash text-xs rounded-full bg-white transform duration-200 ease-in;
}

.btnTertiaryActive {
  @apply hover:bg-bone hover:border-black focus:outline-none focus:ring-1 focus:ring-black focus:bg-bone cursor-pointer;
}

.btnAccent {
  @apply rounded-full border border-transparent px-4 py-2.5 bg-green text-black text-sm hover:bg-black hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:col-start-2 transform duration-200 ease-in;
}

.slideOverContent {
  @apply mt-6 relative flex-1 px-4 sm:px-6;
}

.baseTableCell {
  @apply py-3 px-2 whitespace-nowrap text-sm;
}

.link {
  @apply cursor-pointer border-b border-black hover:bg-green transform duration-300 ease-in-out;
}
