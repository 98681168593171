@import url("//hello.myfonts.net/count/3ef0c2");

@font-face {
  font-family: "Helvetica Now Display";
  src: url("./assets/fonts/HelveticaNowDisplayRegular.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNowDisplayRegular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Now Display";
  src: url("./assets/fonts/HelveticaNowDisplayMedium.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNowDisplayMedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Now Text";
  src: url("./assets/fonts/HelveticaNowTextBold.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNowTextBold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Now Text";
  src: url("./assets/fonts/HelveticaNowTextMedium.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNowTextMedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Helvetica Now Text";
  src: url("./assets/fonts/HelveticaNowTextRegular.woff2") format("woff2"),
    url("./assets/fonts/HelveticaNowTextRegular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
